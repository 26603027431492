/* CustomSpinner.css */

.custom-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background overlay */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  /* Adjust the z-index as needed */
}

.spinner-loader {
  border: 5px solid #f3f3f3;
  /* Light gray border */
  border-top: 5px solid #014C48;
  /* Blue border (change to your desired color) */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.css-nuq0r2-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  height: 3pc !important;
}

.css-wzn73i,
.css-191ympo {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.css-1cohrqd-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  display: flex !important;
}

.css-1cohrqd-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  min-height: 50px !important;
}

.error-message {
  font-size: medium;
  color: red;
  padding: 0px 10px;
}

.filetypeInput {
  display: none !important;
}

.css-7txj2x {
  width: 6rem !important;
}

.iconImg {
  border-radius: 15px;
  overflow: hidden;
}

.css-af8kz-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
  max-height: 20rem !important;
}

.iconImg {
  border-radius: 15px;
  overflow: hidden;
}

.MuiSelect-select {
  display: flex !important;
  min-height: 50px !important;
}

.error-message {
  font-size: medium;
  color: red;
}

.communityImg {
  border-radius: 20px;
  margin-left: 1rem;
  overflow: hidden;
  width: 2rem;
  height: 2rem;
}

.menuItemOption {
  margin-right: 1rem;
}

.br_10 {
  border-radius: 10px;
}

.MuiPaper-root::-webkit-scrollbar {
  display: none;
}

.css-j8mexm-MuiList-root-MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar {
  display: none !important;
}

.css-jlkgr1 {
  font-size: 1rem;
}

.css-w3nzev-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
.css-2xdetu-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #4caf50 !important;
}

.multi_nested_select {
  z-index: 10 !important;
}

.MuiMultiSectionDigitalClockSection-item {
  min-width: 2rem !important;
}

.Mui-selected,
.Mui-selected:hover {
  background-color: #4caf50 !important;
}

.css-1n8sgqj {
  background-size: auto !important;
}

.Mui-error,
.MuiOutlinedInput-notchedOutline {
  color: gray !important;
  border-color: rgb(210 214 218) !important;
}

.Navbar_Profile_Menu {
  display: none !important;
}

.Navbar_Profile_Name {
  display: block !important;
}

@media (max-width: 1200px) {
  .Navbar_Profile_Menu {
    display: block !important;
  }

  .Navbar_Profile_Name {
    display: none !important;
  }

}

.css-1ij7x0a {
  background-color: #ffffff;
}

@media (min-width: 1200px) {
  .css-lvl4a5-MuiDrawer-docked .MuiDrawer-paper {
    width: 290px !important;
  }
}

@media (min-width: 1200px) {
  .css-aim41u {
    margin-left: 20rem!important;
  }
}

.css-mvs481 {
  margin: 0.1rem 0.5rem !important;
  padding: 0.5rem 0.5rem !important;
}

.css-14qyc9s-MuiList-root {
  padding-left: 15px !important;
}

@media (min-width: 1200px) {
  .css-achccn .MuiDrawer-paper {
    width: 290px !important;
  }
}

ul.css-gb55jm {
  padding-left: 15px;
}
.communityImgIcon{
  margin-right: 0.75rem;
  border-radius: 20px;
  margin-left: 0.95rem;
  overflow: hidden;
  width: 2rem;
  height: 2rem;
}